import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import { useModal } from "../context/ModalContext";
import { getNftLeft } from "../../services/mint.service";
import Swal from 'sweetalert2';

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks/account/useGetIsLoggedIn';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account/useGetAccount';
import { SmartContract, Address, ContractFunction, Interaction, TokenTransfer } from "@multiversx/sdk-core/out";
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { scAddress, chainId } from "../../config";

const MintContainer = () => {
    const [sessionId, setTransactionSessionId] = useState<string | null>(null);
    const { openModal } = useModal();
    const isLoggedIn = useGetIsLoggedIn();
    const { address, balance } = useGetAccount();

    const [nftsLeft, setNftsLeft] = useState(0);

    const handleMint = async () => {
        if (!isLoggedIn) {
            console.log('Mint now');
            openModal();

            return;
        }

        await sendMintTransaction();
    }

    const sendMintTransaction = async () => {
        const enoughEGLD = ((Number(balance) / Math.pow(10, 18))) >= 2;

        if (!enoughEGLD) {
            Swal.fire({
                title: 'Not enough balance!',
                icon: 'warning',
                confirmButtonColor: '#04F28B'
            });

            return;
        }

        const mintContract = new SmartContract({ address: Address.fromBech32(scAddress) });
        const mintFunction = new ContractFunction('mintNft');
        const mintTransaction = new Interaction(mintContract, mintFunction, [])
            .withValue(TokenTransfer.egldFromAmount(2))
            .withChainID(chainId)
            .withGasLimit(10_000_000)
            .withSender(Address.fromBech32(address))
            .buildTransaction();

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: [mintTransaction],
            transactionsDisplayInfo: {
                processingMessage: 'Processing Mint transaction',
                errorMessage: 'An error has occured',
                successMessage: 'Transaction successful'
            },
            redirectAfterSign: false
        });
        if (sessionId != null) {
            setTransactionSessionId(sessionId);
        }
    }

    const fetchNftsLeft = async () => {
        const nfts = await getNftLeft();
        setNftsLeft(nfts);
    }

    useEffect(() => {
        //WIP
        // fetchNftsLeft();
    }, [])

    return (
        <React.Fragment>
            <h1 className="text-neon fw-bolder" style={{ fontSize: '45px' }}>ESDT Elite NFT Minting</h1>
            <h3 className="text-neon">Price: 2 $EGLD</h3>
            <VideoPlayer />
            <Button className="rounded-pill text-black bg-neon border-0 fw-bold" onClick={handleMint}>MINT NOW</Button>
        </React.Fragment>
    )
}

export default MintContainer;