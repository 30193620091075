import Modal from 'react-bootstrap/Modal';
import { X } from 'react-bootstrap-icons';
import React from 'react';
import { useModal } from "../context/ModalContext";

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks/account/useGetIsLoggedIn';
import { ExtensionLoginButton } from '@multiversx/sdk-dapp/UI/extension/ExtensionLoginButton/ExtensionLoginButton';
import { LedgerLoginButton } from '@multiversx/sdk-dapp/UI/ledger/LedgerLoginButton/LedgerLoginButton';
import {
    WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI/walletConnect/WalletConnectLoginButton/WalletConnectLoginButton';
import { WebWalletLoginButton } from '@multiversx/sdk-dapp/UI/webWallet/WebWalletLoginButton/WebWalletLoginButton';

const LoginModal: React.FC = () => {
    const { showModal, closeModal } = useModal();

    const handleClose = () => closeModal();
    const isLoggedIn = useGetIsLoggedIn();

    const commonProps = {
        callbackRoute: "",
        nativeAuth: true // optional
    };

    return (
        <React.Fragment>
            {!isLoggedIn &&
                <Modal size="sm" show={showModal} onHide={handleClose} centered>
                    <Modal.Body className="bg-black">
                        <Modal.Title className="text-neon p-3 d-flex align-items-center justify-content-between">
                            <div className="fw-bolder">Login</div>
                            <X className="c-pointer" size={40} onClick={handleClose} />
                        </Modal.Title>
                        <ExtensionLoginButton
                            className="text-neon fs-5 fw-bolder mb-3 p-3 d-flex align-items-center c-pointer bg-black w-75 border-neon"
                            loginButtonText='DeFi Wallet'
                            {...commonProps}
                        >
                            <div onClick={handleClose}>
                                <img style={{ marginRight: "15px" }} alt="" height="30" width="25"
                                     src={'/icons/Defi.png'} />
                                <span>DeFi Wallet</span>
                            </div>
                        </ExtensionLoginButton>
                        <LedgerLoginButton
                            className="text-neon fs-5 fw-bolder mb-3 p-3 d-flex align-items-center c-pointer bg-black w-75 border-neon"
                            loginButtonText='Ledger'
                            {...commonProps}
                        >
                            <div onClick={handleClose}>
                                <img style={{ marginRight: "25px" }} alt="" height="30" width="16"
                                     src={'/icons/Ledger.png'} />
                                <span>Ledger</span>
                            </div>
                        </LedgerLoginButton>
                        <WebWalletLoginButton
                            className="text-neon fs-5 fw-bolder mb-3 p-3 d-flex align-items-center c-pointer bg-black w-75 border-neon"
                            loginButtonText='Web Wallet'
                            {...commonProps}
                        >
                            <div onClick={handleClose}>
                                <img style={{ marginRight: "15px" }} alt="" height="25" width="25"
                                     src={'/icons/Web_Wallet.png'} />
                                <span>Web wallet</span>
                            </div>
                        </WebWalletLoginButton>
                        <WalletConnectLoginButton
                            className="text-neon fs-5 fw-bolder mb-3 p-3 d-flex align-items-center c-pointer bg-black w-75 border-neon"
                            loginButtonText='xPortal App'
                            {...commonProps}
                        >
                            <div>
                                <img className="me-4" alt="" height="30" width="20" src={'/icons/xPortal.png'} />
                                <span>xPortal App</span>
                            </div>
                        </WalletConnectLoginButton>
                    </Modal.Body>
                </Modal>
            }
        </React.Fragment>
    )
};

export default LoginModal;