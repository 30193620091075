import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers";
import { AbiRegistry, Address, SmartContract, ResultsParser } from '@multiversx/sdk-core';
import scAbiFile from '../abi/esdtro-nft-mint.abi.json';
import { GATEWAY, scAddress } from '../config';

const networkProvider = new ProxyNetworkProvider(GATEWAY);
const scAbi = AbiRegistry.create(scAbiFile);
const contract = new SmartContract({ address: Address.fromBech32(scAddress), abi: scAbi });

export const getNftLeft = async () => {
    try {
        let interaction = contract.methods.getNftLeft([]);
        let query = interaction.check().buildQuery();
        let queryResponse = await networkProvider.queryContract(query);
        let typedBundle = new ResultsParser().parseQueryResponse(queryResponse, interaction.getEndpoint());
        const result = typedBundle.values[0].valueOf();
        console.log(`getNftLeft: ${result}`);

        return result;
    } catch (err) {
        console.log(`Error getNftLeft: ${err}`);
        return 0;
    }
}