import React from "react";

const VideoPlayer = () => {
    return (
        <div className="mt-3 mb-3">
            <video width="320" height="320" controls autoPlay muted loop>
                <source src="/video/nft_card.mp4" type="video/mp4" />
            </video>
        </div>
    );
}

export default VideoPlayer;