import S from "./index.module.css";
import Button from "react-bootstrap/Button";
import { useMediaQuery } from "react-responsive";
import { useModal } from "../context/ModalContext";
import { logout } from '@multiversx/sdk-dapp/utils/logout';

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks/account/useGetIsLoggedIn';

const Header = () => {
    const { openModal } = useModal();
    const isLoggedIn = useGetIsLoggedIn();

    const isMobile = useMediaQuery({
        query: '(max-width: 685px)'
    });

    const handleConnect = () => {
        console.log('connect wallet');
        openModal();
    }

    const handleLogout = () => {
        logout();
    }

    const paddingResponsive = isMobile ? 'ps-4 pe-4' : 'ps-6 ps-6';

    return (
        <div className={`${S.header} d-flex align-items-center justify-content-between ${paddingResponsive}`}>
            <img alt="" height="45" width="150" src={'/logo/ESDT_Logo_1.png'} />
            {!isLoggedIn &&
                <>
                    <Button className="rounded-pill text-black bg-neon border-0" onClick={handleConnect}>CONNECT</Button>
                </>
            }
            {isLoggedIn &&
                <>
                    <Button className="rounded-pill text-black bg-neon border-0" onClick={handleLogout}>LOGOUT</Button>
                </>
            }
        </div>
    )
};

export default Header;