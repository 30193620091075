import React from 'react';
import './App.css';
import Header from "./components/header/Header";
import MintContainer from "./components/mintContainer/MintContainer";
import { ModalProvider } from "./components/context/ModalContext";
import LoginModal from "./components/loginModal/LoginModal";
import { AxiosInterceptorContext, DappProvider, NotificationModal, SignTransactionsModals, TransactionsToastList } from "./components/mvx";
import { EnvironmentsEnum } from "@multiversx/sdk-dapp/types";
import { sampleAuthenticatedDomains } from './config';

function App() {
    return (
        <AxiosInterceptorContext.Provider>
            <AxiosInterceptorContext.Interceptor
                authenticatedDomanis={sampleAuthenticatedDomains}
            >
                <ModalProvider>
                    <div className="App">
                        <DappProvider
                            environment={EnvironmentsEnum.mainnet}
                            customNetworkConfig={{
                                name: 'customConfig',
                                walletConnectV2ProjectId: 'c4b36882aa940a9ebc6f90d3e1cbf588'
                            }}
                            dappConfig={{
                                shouldUseWebViewProvider: true
                            }}
                        >
                            <AxiosInterceptorContext.Listener />
                            <TransactionsToastList />
                            <NotificationModal />
                            <SignTransactionsModals className='custom-class-for-modals' />
                            <Header />
                            <div className="app-content">
                                <MintContainer />
                            </div>
                            <LoginModal />
                        </DappProvider>
                    </div>
                </ModalProvider>
            </AxiosInterceptorContext.Interceptor>
        </AxiosInterceptorContext.Provider>
    );
}

export default App;
